import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Welcome from '../components/welcome';

import IndexSEOImage from '../assets/images/banner-homepage.jpg';
import HomeImg1 from '../assets/images/home-callout-01.jpg';
import HomeImg2 from '../assets/images/home-callout-02.jpg';
import HomeImg3 from '../assets/images/home-callout-03.jpg';

class Index extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HomeMeta {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Layout page='index'>
              <SEO
                title='Home'
                description={data.site.siteMetadata.description}
                image={IndexSEOImage}
                keywords={[``]}
              />
              <Hero />
              <Welcome
                siteTitle={data.site.siteMetadata.title}
                classes='darkGrayColorBkgd'
                hasArrow={true}
              />
              <section>
                <Container fluid>
                  <Row className='center-flex flex-column-reverse flex-lg-row'>
                    <Col className='pad40' xs='12' sm='12' md='6' lg='6'>
                      <h3>
                        The U.S. is the only high-income country where maternal
                        mortality is on the rise.
                      </h3>
                      <hr className='horizontalRule dark' />
                      <p>
                        Racial disparities are stark and persistent. According
                        to the CDC, Black, American Indian and Alaska Native
                        women are two to three times more likely to die from
                        pregnancy-related causes than White women, a risk that
                        increases when your lens focuses on cities across the
                        U.S.
                      </p>
                    </Col>
                    <Col className='no-padding' xs='12' sm='12' md='6' lg='6'>
                      <img
                        className='img-responsive'
                        src={HomeImg1}
                        alt='The U.S. is the only high-income country where maternal mortality is on the rise.'
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              <section>
                <Container fluid>
                  <Row className='center-flex'>
                    <Col className='no-padding' xs='12' sm='12' md='6' lg='6'>
                      <img
                        className='img-responsive'
                        src={HomeImg2}
                        alt='Most maternal deaths are preventable.'
                      />
                    </Col>
                    <Col className='pad40' xs='12' sm='12' md='6' lg='6'>
                      <h3>Most maternal deaths are preventable.</h3>
                      <hr className='horizontalRule dark' />
                      <p>
                        Findings from the CDC indicate that at least{' '}
                        <a href='https://www.cdc.gov/media/releases/2022/p0919-pregnancy-related-deaths.html'>
                          80% of all maternal deaths
                        </a>{' '}
                        in America could have been prevented. Maternal mortality
                        review committees have found that these untimely deaths
                        are not just a medical issue. Community factors and the
                        social determinants of health - the conditions in which
                        people live and work - contribute to poor maternal
                        health outcomes.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section>
                <Container fluid>
                  <Row className='center-flex flex-column-reverse flex-lg-row'>
                    <Col className='pad40' xs='12' sm='12' md='6' lg='6'>
                      <h3>We believe in community-led solutions.</h3>
                      <hr className='horizontalRule dark' />
                      <p>
                        Safer Childbirth Cities seeks to be a catalyst for the
                        focused, locally-tailored engagement needed to bring
                        community resources together to strengthen health
                        systems for all to help women have healthy pregnancies,
                        safe childbirths and life-long well-being.
                      </p>
                      <p>
                        <a href='/cities/'>
                          Learn more about our community-based partners in 20
                          cities
                        </a>
                      </p>
                    </Col>
                    <Col className='no-padding' xs='12' sm='12' md='6' lg='6'>
                      <img
                        className='img-responsive'
                        src={HomeImg3}
                        alt='We believe in community-led solutions.'
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            </Layout>
          </>
        )}
      />
    );
  }
}

export default Index;
