import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

class Hero extends React.Component {
    render() {
        return (
            <div>
                <section id='banner' className='homepage'>
                    <Container>
                        <Row>
                            <Col xs='12' sm='12' md='5' lg='5' xl='6'></Col>
                            <Col xs='12' sm='12' md='7' lg='7' xl='6'>
                                <h1>Improving Maternal Health Equity in the U.S.</h1>
                                <hr className='horizontalRule hidden-xs hidden-sm' />
                                <p className='hidden-xs hidden-sm'>The Safer Childbirth Cities initiative aims to support community-based organizations in U.S. cities with a high burden of maternal mortality and morbidity to implement evidence-based interventions and innovative approaches to reverse the country’s maternal health trends and directly tackle racial inequities in maternal health outcomes. Our vision is for cities to become safer &mdash; and more equitable &mdash; places to give birth.</p>
                            </Col>
                        </Row>
                    </Container>
                    <div className='arrow-down hidden-xs hidden-sm'></div>
                    <div className='arrow-block hidden-md hidden-lg'>
                        <div className='arrow-block-filler-small blueColorBkgd'></div>
                        <div className='arrow-down blueColorBkgd teal'></div>
                        <div className='arrow-block-filler blueColorBkgd'></div>
                    </div>
                </section>
                <section id='welcome' className='blueColorBkgd hidden-md hidden-lg'>
                    <Container>
                        <Row>
                            <Col xs='12' sm='12' md='12' lg='12'>
                            <p>The Safer Childbirth Cities initiative aims to support community-based organizations in U.S. cities with a high burden of maternal mortality and morbidity to implement evidence-based interventions and innovative approaches to reverse the country’s maternal health trends and directly tackle racial inequities in maternal health outcomes. Our vision is for cities to become safer &mdash; and more equitable &mdash; places to give birth.</p>
                            </Col>
                        </Row>
                    </Container>
                    <div className='arrow-down blueHomeMobile'></div>
                </section>
            </div>
        )
    }
}

export default Hero